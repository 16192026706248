import { createRouter, createWebHashHistory } from 'vue-router'
import RegistrationView from '../views/RegistrationView.vue'
// import DownloadView from '../views/DownloadView.vue'
import DownloadYybView from '../views/DownloadInYyb.vue'
import ArticleView from '../views/ArticleView.vue'
import InnerMessageView from '../views/InnerMessageView.vue'

const routes = [
  {
    path: '/register/:pid',
    name: 'registration',
    component: RegistrationView
  },
  {
    path: '/download',
    name: 'download',
    component: DownloadYybView
  },
  // {
  //   path: '/yyb/download',
  //   name: 'download-in-yyb',
  //   component: DownloadYybView
  // },
  {
    path: '/article/:id',
    name: 'article',
    component: ArticleView
  },
  {
    path: '/inner-message/:id',
    name: 'inner-message',
    component: InnerMessageView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
